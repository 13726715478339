<template>
  <b-modal no-close-on-backdrop :visible="show" :hide-header="true" centered id="modal-sucesso-checkout" size="md"
    :hide-footer="true">
    <!-- <div class="float-right"><img @click="hide" class="close" src="img/x-icon.svg" /></div> -->
    <div class="row text-center">
      <div class="col-sm-12 col-12 mt-2">
        <img class="pb-3" style="width: 15rem;" src="/img/palmas-sucesso.svg" />

        <h4 class="title-cotacao mb-4 title-bold">Senha criada com sucesso!</h4>
        <h5 class="sub-title-cotacao mb-5">Baixe o App Bike Registrada, complete as informações e siga para a vistoria.
        </h5>

        <div class="d-flex mt-5 justify-center desktop">
          <img class="pointer" @click.prevent="redirectIos" src="/img/ios.svg">
          <img class="ml-3 pointer" @click.prevent="redirectPlay" src="/img/play.svg">
        </div>

        <button class="btn confirm mobile" @click="redirectToAppStore">
          <span class="text">Baixar App Bike Registrada</span><img class="ml-2" src="img/arrow-right.svg" />
        </button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import config from "../../config";
import formatacaoMoeda2 from "../../helpers/formatacaoMoeda2";
export default {
  props: ["show"],
  data() {
    return { config, formatacaoMoeda2 };
  },
  methods: {
    hide() {
      this.$emit("hide");
    },
    redirectIos() {
      return window.open('https://apps.apple.com/br/app/bike-registrada/id1661678422', '_blank');
    },
    redirectPlay() {
      return window.open('https://play.google.com/store/apps/details?id=br.com.seguro.bikeregistrada', '_blank');
    },
    redirectToAppStore() {
      const userAgent = navigator.userAgent || window.opera;

      if (/iPhone|iPad|iPod/i.test(userAgent)) {
        // Dispositivo iOS
        window.open('https://apps.apple.com/br/app/bike-registrada/id1661678422', '_blank');
      } else {
        // Dispositivo Android
        window.open('https://play.google.com/store/apps/details?id=br.com.seguro.bikeregistrada', '_blank');
      }
    }
  },
};
</script>

<style>
#modal-sucesso-checkout___BV_modal_content_ {
  border-radius: 12px !important;
  padding: 25px;
  padding-top: 15px;
}

.justify-center {
  justify-content: center;
}

.ghost {
  text-decoration: none;
  border: 1px solid;
  border-radius: 15px;
  padding: 15px;
}

.title-bold {
  font-weight: 800;
}

.close {
  width: 15px;
  cursor: pointer;
}

.vw-20 {
  width: 20vw;
}

.voltar {
  color: #9a9a9a;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.confirm {
  font-size: 18px;
  padding: 12px 20px 12px 20px;
  border-radius: 12px;
  border: 1px solid #4FA935;
  background: #4FA935;
  color: #fff;
}

.title-cotacao {
  color: #425994;
}

.sub-title-cotacao {
  color: #78849E;
}


@media (max-width: 768px) {
  .desktop {
    display: none !important
  }

  .title-cotacao {
    font-size: 15px !important;
    font-weight: bold;
  }

  .sub-title-cotacao {
    font-size: 14px !important;
  }

  .sub-title {
    font-size: 17px;
  }

  .title {
    font-size: 20px;
  }

  .vw-m-60 {
    width: 30vw;
  }

  #modal-sucesso-checkout___BV_modal_content_ {
    padding: 0 !important;
    padding-top: 0 !important;
  }
}

@media (min-width: 769px) {
  .mobile {
    display: none !important
  }
}
</style>
